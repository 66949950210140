body {
  background-color: black !important;
  color: #f8f8f8 !important;
  display: flex;
  justify-content: center;
  text-align: center;
}

a{
  color:#f8f8f8 !important;
  text-decoration: none !important;
}

.logo-image{
  padding-left: 0.7em;
}
.social-links{
  display: flex;
  justify-content: center;
  font-size: xx-large;
}
.social-icon{
  margin: 0.6em;
}

.partner-tile{
  color: black !important;
}
.partner-tile>img{
  width:100%;
  object-fit: contain;
}
.row {
  margin-bottom: 2em;
}

@media (max-width:575px){
  .partner-tile{
    width: 18rem;
    min-height: 18rem;
    margin: 1em auto;
  }
  .partner-tile>img{
    min-height:50%;
  }
}
@media (min-width:576px){
  .partner-tile{
    height: 20rem !important;
  }
  .partner-tile>img{
  }

}